import { FC } from "react";
import { Navigate, Routes, Route } from "react-router";
import { SnackbarProvider } from "notistack";

import { UserProvider } from "@encoderinc/provider-user";
import { SettingsProvider } from "@encoderinc/provider-settings";
import { LocalizationProvider } from "@encoderinc/provider-localization";
import { ApiProvider } from "@encoderinc/provider-api";
import { i18n } from "@memoryos/localization-admin-ui";
import { PickerProvider } from "@encoderinc/mui-provider-picker";
import { EnabledLanguages } from "@memoryos/constants";
import { Error, Message } from "@encoderinc/common-pages";
import { FirebaseLogin } from "@memoryos/firebase-login";

import { Protected } from "../components/common/protected";

import { Answer } from "./curriculum/answer";
import { Dashboard } from "./dashboard";
import { Lesson } from "./curriculum/lesson";
import { Module } from "./curriculum/module";
import { Profile } from "./profile";
import { PromoCode } from "./money/promo-code";
import { Step } from "./curriculum/step";
import { User } from "./user";
import { Leads } from "./quiz/leads";
import { Products } from "./solidgate/products";
import { Orders } from "./solidgate/orders";
import { Subscriptions } from "./subscriptions";

import { Layout } from "../components/common/layout";
import { Admin } from "./admin";

const App: FC = () => {
  return (
    <ApiProvider baseUrl={process.env.BE_URL}>
      <UserProvider>
        <SettingsProvider defaultLanguage={EnabledLanguages.EN}>
          <LocalizationProvider i18n={i18n} defaultLanguage={EnabledLanguages.EN}>
            <SnackbarProvider>
              <PickerProvider>
                <Routes>
                  <Route element={<Layout />}>
                    <Route path="/" element={<Navigate to="/dashboard" />} />

                    <Route path="/login" element={<FirebaseLogin />} />

                    <Route
                      path="/dashboard"
                      element={
                        <Protected>
                          <Dashboard />
                        </Protected>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <Protected>
                          <Profile />
                        </Protected>
                      }
                    />
                    <Route
                      path="/profile/:tab"
                      element={
                        <Protected>
                          <Profile />
                        </Protected>
                      }
                    />
                    <Route
                      path="/predefined-admins"
                      element={
                        <Protected>
                          <Admin />
                        </Protected>
                      }
                    />
                    <Route
                      path="/predefined-admins/:id"
                      element={
                        <Protected>
                          <Admin />
                        </Protected>
                      }
                    />
                    <Route
                      path="/admins"
                      element={
                        <Protected>
                          <User adminPage={true} />
                        </Protected>
                      }
                    />
                    <Route
                      path="/admins/:id"
                      element={
                        <Protected>
                          <User adminPage={true} />
                        </Protected>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <Protected>
                          <User />
                        </Protected>
                      }
                    />
                    <Route
                      path="/users/:id"
                      element={
                        <Protected>
                          <User />
                        </Protected>
                      }
                    />

                    <Route
                      path="/modules"
                      element={
                        <Protected>
                          <Module />
                        </Protected>
                      }
                    />
                    <Route
                      path="/modules/:id"
                      element={
                        <Protected>
                          <Module />
                        </Protected>
                      }
                    />
                    <Route
                      path="/lessons"
                      element={
                        <Protected>
                          <Lesson />
                        </Protected>
                      }
                    />
                    <Route
                      path="/lessons/:id"
                      element={
                        <Protected>
                          <Lesson />
                        </Protected>
                      }
                    />
                    <Route
                      path="/steps"
                      element={
                        <Protected>
                          <Step />
                        </Protected>
                      }
                    />
                    <Route
                      path="/steps/:id"
                      element={
                        <Protected>
                          <Step />
                        </Protected>
                      }
                    />
                    <Route
                      path="/answers"
                      element={
                        <Protected>
                          <Answer />
                        </Protected>
                      }
                    />
                    <Route
                      path="/answers/:id"
                      element={
                        <Protected>
                          <Answer />
                        </Protected>
                      }
                    />

                    <Route
                      path="/promo-codes"
                      element={
                        <Protected>
                          <PromoCode />
                        </Protected>
                      }
                    />

                    <Route
                      path="/promo-codes/:id"
                      element={
                        <Protected>
                          <PromoCode />
                        </Protected>
                      }
                    />

                    <Route
                      path="/quiz/leads"
                      element={
                        <Protected>
                          <Leads />
                        </Protected>
                      }
                    />
                    <Route
                      path="/quiz/leads/:id"
                      element={
                        <Protected>
                          <Leads />
                        </Protected>
                      }
                    />

                    <Route
                      path="/solidgate/products"
                      element={
                        <Protected>
                          <Products />
                        </Protected>
                      }
                    />
                    <Route
                      path="/solidgate/products/:id"
                      element={
                        <Protected>
                          <Products />
                        </Protected>
                      }
                    />

                    <Route
                      path="/solidgate/orders"
                      element={
                        <Protected>
                          <Orders />
                        </Protected>
                      }
                    />
                    <Route
                      path="/solidgate/orders/:id"
                      element={
                        <Protected>
                          <Orders />
                        </Protected>
                      }
                    />

                    <Route
                      path="/solidgate/subscriptions"
                      element={
                        <Protected>
                          <Subscriptions />
                        </Protected>
                      }
                    />
                    <Route
                      path="/solidgate/subscriptions/:id"
                      element={
                        <Protected>
                          <Subscriptions />
                        </Protected>
                      }
                    />

                    <Route path="/error/:error" element={<Error />} />
                    <Route path="/message/:message" element={<Message />} />

                    <Route path="*" element={<Navigate to="/message/page-not-found" />} />
                  </Route>
                </Routes>
              </PickerProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </SettingsProvider>
      </UserProvider>
    </ApiProvider>
  );
};

export default App;
