import { FC, Fragment } from "react";
import { Divider, Typography, Button, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { FormDialog } from "@encoderinc/mui-dialog-form";
import { ISubscription } from "@memoryos/types";
import { useStyles } from "./styles";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export interface IShowSubscriptionsDialogProps {
  open: boolean;
  onCancel: () => void;
  values: ISubscription;
  handleSubscriptionCancel: () => void;
}

export const ShowSubscriptionsDialog: FC<IShowSubscriptionsDialogProps> = props => {
  const { values, onCancel, handleSubscriptionCancel, ...rest } = props;
  const {
    customerAccountId,
    customerEmail,
    customerEmailAlternative,
    productId,
    solidgateId,
    originalOrderId,
    status,
    startedAt,
    expiredAt,
    cancelledAt,
    nextChargeAt,
    paymentType,
    amount,
    currency,
    trial,
    trialAmount,
    trialCurrency,
    trialPeriod,
    customerName,
    trialEndNotificationDate,
    preBillingNotificationDate,
    name,
    ratePlanType,
    termType,
    isSoftCancel,
    cancellationTypeInitiatedByUser,
  } = values;

  const classes = useStyles();

  return (
    <FormDialog
      message={values.customerEmail}
      onConfirm={onCancel}
      onCancel={onCancel}
      initialValues={{}}
      showButtons={false}
      showPrompt={false}
      {...rest}
    >
      <Grid container>
        <Grid item xs={8}>
          {customerName && (
            <Fragment>
              <Typography>
                <b>
                  <FormattedMessage id="pages.solidgate.subscription.show.customerName" />
                  {": "}
                </b>
                {customerName}
              </Typography>
              <br />
            </Fragment>
          )}
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.customerAccountId" />
              {": "}
            </b>
            {customerAccountId}
          </Typography>
          <br />
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.customerEmail" />
              {": "}
            </b>
            {customerEmail}
          </Typography>
          <br />
          {customerEmailAlternative && (
            <Fragment>
              <Typography>
                <b>
                  <FormattedMessage id="pages.solidgate.subscription.show.customerEmailAlternative" />
                  {": "}
                </b>
                {customerEmailAlternative}
              </Typography>
              <br />
            </Fragment>
          )}
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" color="error" className={classes.button} onClick={handleSubscriptionCancel}>
            <FormattedMessage id="pages.solidgate.subscription.show.cancel" />
          </Button>
        </Grid>
      </Grid>

      <Divider />
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.ratePlanType" />
          {": "}
        </b>
        {ratePlanType}
      </Typography>
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.termType" />
          {": "}
        </b>
        {termType}
      </Typography>
      <br />
      <Divider />

      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.name" />
          {": "}
        </b>
        {name}
      </Typography>
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.productId" />
          {": "}
        </b>
        {productId}
      </Typography>
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.solidgateId" />
          {": "}
        </b>
        {solidgateId}
      </Typography>
      <br />
      {originalOrderId && (
        <Fragment>
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.originalOrderId" />
              {": "}
            </b>
            {originalOrderId}
          </Typography>
          <br />
        </Fragment>
      )}
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.status" />
          {": "}
        </b>
        {status}
      </Typography>
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.startedAt" />
          {": "}
        </b>
        {startedAt}
      </Typography>
      <br />
      {expiredAt && (
        <Fragment>
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.expiredAt" />
              {": "}
            </b>
            {expiredAt}
          </Typography>
          <br />
        </Fragment>
      )}
      {cancelledAt && (
        <Fragment>
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.cancelledAt" />
              {": "}
            </b>
            {cancelledAt}
          </Typography>
          <span className={classes.hint}>
            <FormattedMessage id="pages.solidgate.subscription.show.cancelledAtFieldWarning" />
          </span>
          <br />
          <br />

          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.isSoftCancel" />
              {": "}
            </b>
            {isSoftCancel.toString()}
          </Typography>
          <br />
        </Fragment>
      )}

      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.cancellationTypeInitiatedByUser" />
          {": "}
        </b>
        {cancellationTypeInitiatedByUser}
      </Typography>
      <br />

      <Divider />

      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.paymentType" />
          {": "}
        </b>
        {paymentType}
      </Typography>
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.amount" />
          {": "}
        </b>
        {formatter.format(Number(amount) / 100)}
      </Typography>
      <br />
      <Typography>
        <b>
          <FormattedMessage id="pages.solidgate.subscription.show.currency" />
          {": "}
        </b>
        {currency}
      </Typography>
      <br />
      {nextChargeAt && (
        <Fragment>
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.nextChargeAt" />
              {": "}
            </b>
            {nextChargeAt}
          </Typography>
          <br />
        </Fragment>
      )}
      {trialEndNotificationDate && (
        <Fragment>
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.trialEndNotificationDate" />
              {": "}
            </b>
            {trialEndNotificationDate}
          </Typography>
          <br />
        </Fragment>
      )}
      {preBillingNotificationDate && (
        <Fragment>
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.preBillingNotificationDate" />
              {": "}
            </b>
            {preBillingNotificationDate}
          </Typography>
          <br />
        </Fragment>
      )}
      <Divider />
      {typeof trial !== "undefined" && (
        <Fragment>
          <br />
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.trial" />
              {": "}
            </b>
            {trial.toString()}
          </Typography>
        </Fragment>
      )}
      {trialAmount && (
        <Fragment>
          <br />
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.trialAmount" />
              {": "}
            </b>
            {formatter.format(Number(trialAmount) / 100)}
          </Typography>
        </Fragment>
      )}
      {trialCurrency && (
        <Fragment>
          <br />
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.trialCurrency" />
              {": "}
            </b>
            {trialCurrency}
          </Typography>
        </Fragment>
      )}
      {trialPeriod && (
        <Fragment>
          <br />
          <Typography>
            <b>
              <FormattedMessage id="pages.solidgate.subscription.show.trialPeriod" />
              {": "}
            </b>
            {trialPeriod}
          </Typography>
        </Fragment>
      )}
    </FormDialog>
  );
};
