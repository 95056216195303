import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  () => ({
    section: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 64px)",
      width: 250,
      margin: "0 auto",
    },
    noCodesMessage: {
      fontSize: "15px",
      marginTop: "5px",
    },
  }),
  { name: "UserEdit" },
);
