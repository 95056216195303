import React, { FC } from "react";
import { format, parseISO } from "date-fns";
import { Typography, List, ListItem, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { SelectInput, StaticInput, TextInput } from "@encoderinc/mui-inputs-core";
import { FormDialog } from "@encoderinc/mui-dialog-form";
import { IUser, UserRole, UserStatus } from "@memoryos/types";
import { humanReadableDateTimeFormat } from "@memoryos/constants";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

export interface IEditUserDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: Partial<IUser>, formikBag: any) => Promise<void>;
  initialValues: IUser;
}

export const EditUserDialog: FC<IEditUserDialogProps> = props => {
  const { initialValues, ...rest } = props;

  const classes = useStyles();

  const {
    id,
    displayName,
    userRoles,
    userStatus,
    createdAt,
    solidgateCustomerAccountId,
    email,
    fid,
    promoCodes,
    aid,
    cid,
    fbid,
    ip,
    country,
    userAgent,
    lastLoginAt,
  } = initialValues;

  const fixedValues = {
    id,
    displayName,
    userRoles,
    userStatus,
  };

  const renderPromoCodesList = () => {
    if (!promoCodes?.length) {
      return (
        <Typography className={classes.noCodesMessage}>
          <FormattedMessage id="pages.users.edit.noActivatedPromocodes" />
        </Typography>
      );
    }

    return (
      <List>
        {promoCodes?.map(promoCode => {
          return (
            <ListItem key={promoCode.code}>
              <Link to={`/promo-codes/${promoCode.id}`} target="_blank" rel="noopener noreferrer">
                {promoCode.code}
              </Link>
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <FormDialog initialValues={fixedValues} message="dialogs.edit" {...rest}>
      <Grid container spacing={3} style={{ display: "flex", alignItems: "top" }}>
        <Grid item xs={8}>
          <StaticInput name="id" disabled={true} value={id} />
          <TextInput name="displayName" disabled={true} value={displayName} />
          <StaticInput name="email" disabled={true} value={email} />
        </Grid>
        <Grid item xs={4}>
          <StaticInput name="createdAt" value={format(parseISO(createdAt), humanReadableDateTimeFormat)} />
          <StaticInput name="lastLoginAt" value={lastLoginAt != null ? format(parseISO(lastLoginAt), humanReadableDateTimeFormat) : "Unknown"} />
        </Grid>
      </Grid>
      <Box sx={{ mt: 8 }}>
        <Divider sx={{ fontWeight: "bold" }}>
          <FormattedMessage id="pages.users.edit.section.ids" />
        </Divider>
      </Box>
      <Grid container spacing={3} style={{ display: "flex", alignItems: "top" }}>
        <Grid item xs={6}>
          <StaticInput name="fid" disabled={true} value={String(fid)} />
          <StaticInput name="cid" disabled={true} value={String(cid)} />
          <StaticInput name="aid" disabled={true} value={String(aid)} />
        </Grid>
        <Grid item xs={6}>
          <StaticInput name="solidgateCustomerAccountId" value={solidgateCustomerAccountId || "null"} disabled={true} />
          <StaticInput name="fbid" disabled={true} value={String(fbid)} />
        </Grid>
      </Grid>
      <Box sx={{ mt: 8 }}><Divider sx={{ fontWeight: 'bold' }}><FormattedMessage
        id="pages.users.edit.section.location" /></Divider></Box>
      <Grid container spacing={4} style={{ display: "flex", alignItems: "top" }}>
        <Grid item xs={8}>
          <StaticInput name="ip" disabled={true} value={String(ip)} />
          <StaticInput name="userAgent" disabled={true} value={String(userAgent)} />
        </Grid>
        <Grid item xs={4}>
          <StaticInput name="country" disabled={true} value={String(country)} />
        </Grid>
      </Grid>
      <Box sx={{ mt: 8 }}>
        <Divider sx={{ fontWeight: "bold" }}>
          <FormattedMessage id="pages.users.edit.section.other" />
        </Divider>
      </Box>
      <SelectInput name="userRoles" options={UserRole} multiple />
      <SelectInput name="userStatus" options={UserStatus} />
      <br />
      <br />
      <FormattedMessage id="pages.users.edit.promoCodes" />
      <br />
      {renderPromoCodesList()}
    </FormDialog>
  );
};
